.blog-post{
    .featured-image{
        height: 33vh;
        overflow: hidden;
        background-position: center center;
        background-size: cover;
    }
    .post-content{
        ol, ul{
            list-style: auto;
            @apply ml-4;
        }
    }

}