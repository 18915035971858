.main-menu{
    .menu-item{
        //@extend md\:flex-column;
        a{
            @apply flex;
            @apply flex-row;
            @apply py-1;
            @apply px-4;
        }
    }
}