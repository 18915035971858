$emerald-500: #10b981;
.med-reports{
    h1, h2, h3, h4, h5 {
        color:#10b981
    }
    .btn-active {
        background-color: $emerald-500;
        color: #fff;
        font-size: 2rem;
    }
    .text-big{
        font-size: 2rem;
    }
    .chart{
        width: 100%;
        height: 400px;
    }
}
.med-form{
    h2{
        font-size: 1.5rem;
        font-weight: 300;
        margin: 0;
    }
    h3{
        font-size: 1.25rem;
        font-weight: 300;
        margin: 0;
    }
    input, textarea{
        border:1px solid $emerald-500;
        padding:0.5rem;
        font-size: 2rem;
        width:100%;
        text-align: center;
        min-height: 6rem;
        &::placeholder{
            opacity: 0.25;
        }
    }
    textarea{
        text-align: left;
        font-size: 1.65rem;
    }
    
}

.medication{
    border:1px solid;
    border-radius:1rem;
    border-color: $emerald-500;
    .label{
        font-size:1.5rem;
    }
    .radio{
        opacity: 0;
    }
    i.fa-check {
        font-size: 2rem;
        color: $emerald-500;
        opacity: 0;
        &.active{
            opacity: 1;
        }
    }
}