@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* Default font */
    body {
        @apply text-gray-500 font-body;
    }

    /* Disable OSX scrolling bounce effect */
    html,
    body {
        overscroll-behavior: none;
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-gray-700 font-heading mb-6;
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-2xl;
    }

    h3 {
        @apply text-xl;
    }

    h4 {
        @apply text-lg;
    }

    /* Paragraphs and spacing */
    p {
        @apply mb-4;
    }

    p:last-child {
        @apply mb-0;
    }

    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6 {
        @apply mt-8;
    }

    /* Hero */
    .hero {
        @apply px-16 pt-52 pb-28 text-center lg:px-40;
    }

    .hero-small {
        @apply px-16 pt-16 pb-8 text-center lg:px-40;
    }

    summary{
        display: inline-block;
    }
}
// You probably already include the core styles
@import "./fontawesome-free/scss/fontawesome.scss";

// You can include all the other styles the same as before
@import "./fontawesome-free/scss/solid.scss";
@import "./fontawesome-free/scss/brands.scss";

@import 'general/menu';
@import 'static/main';
@import 'static/my-cv';
@import 'blog/post';
@import 'health/health';
