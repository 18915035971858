$lineColor: #555;
$ballColor: #333;
.timeline{
    .cv-entry{
        display: flex;
        width: 50%;
        position: relative;
        .marker{
            padding: 0 1.5rem;
            display:flex;
            align-items: center;
            justify-content: center;
            i.line{
                display:block;
                border-right:2px solid $lineColor;
                width:1px;
                height:100%;
            }
            i.ball{
                margin-left: -0.55rem;
                display:block;
                width:1rem;
                height:1rem;
                background-color: $ballColor;
                border-radius: 50%;
            }
        }
        .cv-entry-content{
            padding:1rem;
            width: 50vw;
            margin: 0.5rem;
            @apply bg-white;
            @apply shadow;
            @apply rounded;
            .dates{
                display: flex;
                width: 50%;   
                font-size: 85%;
            }

        }
        &:first-child{
            .marker{
                .line{
                    height: 50%;
                    align-self: end;
                }
            }
        }
        &:last-child{
            .marker{
                .line{
                    height: 50%;
                    align-self: start;
                }
            }
        }
        &.current{

        .marker{
            i.ball{
                @apply bg-red-400;
            }
        }
            .cv-entry-content{
                @apply shadow-red-400;
            }
        }
    }
    &.autowidth{
        .cv-entry{
            width:100%;
        }
    }
}
@media (max-width: 768px){
    .timeline{
        .cv-entry{
            width: 100%;
            .cv-entry-content{
                width: 100%;
                margin-right: 1rem;
                .dates{
                    font-size: 90%;
                    flex-direction: column;
                    width: 100%;
                }
            }
        }
    }
}